import * as ACTION_TYPES from './Types'

const initialState = {
    loading: false,
    trainerDetails: {},
    userAppoinmets: [],
    transactions: [],
    scheduleTimes: {},
    message: '',
    error: null
}

export const userAppoinmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_REQUEST_TRAINER_REQUEST:
        case ACTION_TYPES.GET_ALL_EVENTS_USER_REQUEST:
        case ACTION_TYPES.GENERATE_USER_APPOINMENT_REQUEST:
        case ACTION_TYPES.GET_PAYMENT_HISTORY_REQUEST:
        case ACTION_TYPES.DOWNLOAD_PAYMENT_RECIEPT_REQUEST:
        case ACTION_TYPES.BACK_TO_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.BACK_TO_SEARCH_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case ACTION_TYPES.BACK_TO_SEARCH_ERROR: {
            return {
                ...state,
                loading: false
            }
        }
        case ACTION_TYPES.SCHEDULETIMES_SUCCESS:
            return {
                ...state,
                loading: false,
                scheduleTimes: action.payload
            }
        case ACTION_TYPES.GET_REQUEST_TRAINER_SUCCESS:
            return {
                ...state,
                loading: false,
                trainerDetails: action.payload.data,
                error: null
            }
        case ACTION_TYPES.GET_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: action.payload?.data?.length > 0 ? action.payload?.data.reverse() : []
            }
        case ACTION_TYPES.GET_ALL_EVENTS_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userAppoinmets: action.payload.data,
                error: null
            }
        case ACTION_TYPES.GENERATE_USER_APPOINMENT_SUCCESS:
        case ACTION_TYPES.GENERATE_USER_APPOINMENT_ERROR:
        case ACTION_TYPES.GET_PAYMENT_HISTORY_ERROR:
        case ACTION_TYPES.DOWNLOAD_PAYMENT_RECIEPT_SUCCESS:
        case ACTION_TYPES.DOWNLOAD_PAYMENT_RECIEPT_ERROR:
            return {
                ...state,
                loading: false
            }
        case ACTION_TYPES.GET_REQUEST_TRAINER_ERROR:
        case ACTION_TYPES.GET_ALL_EVENTS_USER_ERROR:
            return {
                ...state,
                loading: false,
                trainerDetails: {},
                userAppoinmets: []
            }
        default:
            return state;
    }
}