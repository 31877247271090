
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from "redux"

// reducers
import { planGolaServiceReducer } from './ServiceGoalPlan/Reducer'
import { userSignupReducer } from './UserSignup/Reducer'
import { userLoginReducer } from './Login/Reducer'
import { trainerLoginReducer } from './TrainerLogin/Reducer'
import { customerProfileReducer } from './CustomerProfile/Reducer'
import { trainerProfileReducer } from './TrainerProfile/Reducer'
import { paymentReducer } from './Payment/Reducer'
import { cmsReducer } from './CMS/Reducer'
import { userAppoinmentReducer } from './UserAppointment/Reducer'
import { trainerAppointmentReducer } from './TrainerAppointment/Reducer'

export const rootReducer = combineReducers({
    form: formReducer,
    planGolaService: planGolaServiceReducer,
    userSignup: userSignupReducer,
    userLogin: userLoginReducer,
    trainerLogin: trainerLoginReducer,
    customerProfile: customerProfileReducer,
    trainerProfile: trainerProfileReducer,
    payment: paymentReducer,
    userAppoinment: userAppoinmentReducer,
    trainerAppointment: trainerAppointmentReducer,
    cms: cmsReducer
});