// customer signup
export const CUSTOMER_SIGNUP_REQUEST = "CUSTOMER_SIGNUP_REQUEST"
export const CUSTOMER_SIGNUP_SUCCESS = "CUSTOMER_SIGNUP_SUCCESS"
export const CUSTOMER_SIGNUP_FAIL = "CUSTOMER_SIGNUP_FAIL"

// trainner signup
export const TRAINNER_SIGNUP_REQUEST = "TRAINNER_SIGNUP_REQUEST"
export const TRAINNER_SIGNUP_SUCCESS = "TRAINNER_SIGNUP_SUCCESS"
export const TRAINNER_SIGNUP_FAIL = "TRAINNER_SIGNUP_FAIL"

// trainner signup
export const TRAINNER_SIGNUP_FIRST_STEP_SUCCESS = "TRAINNER_SIGNUP_FIRST_STEP_SUCCESS"
// export const TRAINNER_SIGNUP_SUCCESS = "TRAINNER_SIGNUP_SUCCESS"