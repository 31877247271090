import crypto from 'crypto'

const algorithm = 'aes192'
const password = 'erqAFxxCshjKla'

// To encrypt string
export const encrypt = (text) => {
    var cipher = crypto.createCipher(algorithm, password)
    var crypted = cipher.update(text, 'utf8', 'hex')
    crypted += cipher.final('hex')
    return crypted
}

// To decrypt string
export const decrypt = (text) => {
    var decipher = crypto.createDecipher(algorithm, password)
    var dec = decipher.update(text, 'hex', 'utf8')
    dec += decipher.final('utf8')
    return dec
}

// Remove underscore and Capitalize string
export const humanize = (str) => {
    var frags = str.split('_')
    for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
}

export const swap = (input, a, b, event) => {
    if (event === 'up' && !b <= 0) {
        if (!b <= 0) {
            let temp = input[a]
            input[a] = input[b]
            input[b] = temp
            return input
        }
    } else if (event === 'down' && b < input.length - 1) {
        let temp = input[a]
        input[a] = input[b]
        input[b] = temp
        return input
    }
}

//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

//To concate the path for the user profile image path
export const toUserProfileImage = pathname => (`${process.env.REACT_APP_IMAGE_BASE}uploads/users/profilePicture/${pathname}`)

//To concate the path for the user intro video/thumbnail path
export const toUserProfileVideo = (pathname, dir) => (`${process.env.REACT_APP_IMAGE_BASE}uploads/users/${dir}/${pathname}`)

//To concate the path for the user intro gif path
export const toUserGif = pathname => (`${process.env.REACT_APP_IMAGE_BASE}uploads/users/gif/${pathname}`)

export const stateOptions = [{
    value: 'AL',
    label: 'Alabama'
}, {
    value: 'AK',
    label: 'Alaska'
}, {
    value: 'AZ',
    label: 'Arizona'
}, {
    value: 'AR',
    label: 'Arkansas'
}, {
    value: 'CA',
    label: 'California'
}, {
    value: 'CO',
    label: 'Colorado'
}, {
    value: 'CT',
    label: 'Connecticut'
}, {
    value: 'DE',
    label: 'Delaware'
}, {
    value: 'FL',
    label: 'Florida'
}, {
    value: 'GA',
    label: 'Georgia'
}, {
    value: 'HI',
    label: 'Hawaii'
}, {
    value: 'ID',
    label: 'Idaho'
}, {
    value: 'IL',
    label: 'Illinois'
}, {
    value: 'IN',
    label: 'Indiana'
}, {
    value: 'IA',
    label: 'Iowa'
}, {
    value: 'KS',
    label: 'Kansas'
}, {
    value: 'KY',
    label: 'Kentucky'
}, {
    value: 'LA',
    label: 'Louisiana'
}, {
    value: 'ME',
    label: 'Maine'
}, {
    value: 'MD',
    label: 'Maryland'
}, {
    value: 'MA',
    label: 'Massachusetts'
}, {
    value: 'MI',
    label: 'Michigan'
}, {
    value: 'MN',
    label: 'Minnesota'
}, {
    value: 'MS',
    label: 'Mississippi'
}, {
    value: 'MO',
    label: 'Missouri'
}, {
    value: 'MT',
    label: 'Montana'
}, {
    value: 'NE',
    label: 'Nebraska'
}, {
    value: 'NV',
    label: 'Nevada'
}, {
    value: 'NH',
    label: 'New Hampshire'
}, {
    value: 'NJ',
    label: 'New Jersey'
}, {
    value: 'NM',
    label: 'New Mexico'
}, {
    value: 'NY',
    label: 'New York'
}, {
    value: 'NC',
    label: 'North Carolina'
}, {
    value: 'ND',
    label: 'North Dakota'
}, {
    value: 'OH',
    label: 'Ohio'
}, {
    value: 'OK',
    label: 'Oklahoma'
}, {
    value: 'OR',
    label: 'Oregon'
}, {
    value: 'PA',
    label: 'Pennsylvania'
}, {
    value: 'RI',
    label: 'Rhode Island'
}, {
    value: 'SC',
    label: 'South Carolina'
}, {
    value: 'SD',
    label: 'South Dakota'
}, {
    value: 'TN',
    label: 'Tennessee'
}, {
    value: 'TX',
    label: 'Texas'
}, {
    value: 'UT',
    label: 'Utah'
}, {
    value: 'VT',
    label: 'Vermont'
}, {
    value: 'VA',
    label: 'Virginia'
}, {
    value: 'WA',
    label: 'Washington'
}, {
    value: 'WV',
    label: 'West Virginia'
}, {
    value: 'WI',
    label: 'Wisconsin'
}, {
    value: 'WY',
    label: 'Wyoming'
}, {
    value: 'DC',
    label: 'District of Columbia'
}, {
    value: 'MH',
    label: 'Marshall Islands'
}]

export const getTheOption = state => {
    const option = stateOptions.find(s => s.value === state)
    return option && option.label
}

export const month = [
    { value: 0, label: 'January', abbreviation: 'Jan' },
    { value: 1, label: 'February', abbreviation: 'Feb' },
    { value: 2, label: 'March', abbreviation: 'Mar' },
    { value: 3, label: 'April', abbreviation: 'Apr' },
    { value: 4, label: 'May', abbreviation: 'May' },
    { value: 5, label: 'June', abbreviation: 'Jun' },
    { value: 6, label: 'July', abbreviation: 'Jul' },
    { value: 7, label: 'August', abbreviation: 'Aug' },
    { value: 8, label: 'September', abbreviation: 'Sep' },
    { value: 9, label: 'October', abbreviation: 'Oct' },
    { value: 10, label: 'November', abbreviation: 'Nov' },
    { value: 11, label: 'December', abbreviation: 'Dec' }
]

//Dropdown values of year
export const year = Array.from(new Array(120), (val, index) => (
    {
        value: (new Date()).getFullYear() - index,
        label: ((new Date()).getFullYear() - index).toString()
    })
)

export const convertMonthToString = monthInNumber => {
    let ans = month.find(m => m.value === monthInNumber && m.label)
    return ans.abbreviation
}

export const comparePopularForSort = (a, b) => {
    if (a.popularCount > b.popularCount) {
        return -1
    } else if (a.popularCount < b.popularCount) {
        return 1
    } else {
        return 0
    }
}

export const formatPhoneNumber = phoneNumberString => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    return { hours, minutes };
}


export function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
};