export const GET_TRAINER_APPOINMENT_SUCCESS = "GET_TRAINER_APPOINMENT_SUCCESS"
export const GET_TRAINER_APPOINMENT_REQUEST = "GET_TRAINER_APPOINMENT_REQUEST"
export const GET_TRAINER_APPOINMENT_ERROR = "GET_TRAINER_APPOINMENT_ERROR"

export const ACCEPT_APPOINTMENT_SUCCESS = "ACCEPT_APPOINTMENT_SUCCESS"
export const ACCEPT_APPOINTMENT_REQUEST = "ACCEPT_APPOINTMENT_REQUEST"
export const ACCEPT_APPOINTMENT_ERROR = "ACCEPT_APPOINTMENT_ERROR"

export const BLOCK_DATE_SUCCESS = "BLOCK_DATE_SUCCESS"
export const BLOCK_DATE_REQUEST = "BLOCK_DATE_REQUEST"
export const BLOCK_DATE_ERROR = "BLOCK_DATE_ERROR"

export const UNBLOCK_DATE_SUCCESS = "UNBLOCK_DATE_SUCCESS"
export const UNBLOCK_DATE_REQUEST = "UNBLOCK_DATE_REQUEST"
export const UNBLOCK_DATE_ERROR = "UNBLOCK_DATE_ERROR"


export const GET_TRAINER_PAYMENT_HISTORY_SUCCESS = "GET_TRAINER_PAYMENT_HISTORY_SUCCESS"
export const GET_TRAINER_PAYMENT_HISTORY_REQUEST = "GET_TRAINER_PAYMENT_HISTORY_REQUEST"
export const GET_TRAINER_PAYMENT_HISTORY_ERROR = "GET_TRAINER_PAYMENT_HISTORY_ERROR"

export const DOWNLOAD_TRAINER_PAYMENT_RECIEPT_SUCCESS = "DOWNLOAD_TRAINER_PAYMENT_RECIEPT_SUCCESS"
export const DOWNLOAD_TRAINER_PAYMENT_RECIEPT_REQUEST = "DOWNLOAD_TRAINER_PAYMENT_RECIEPT_REQUEST"
export const DOWNLOAD_TRAINER_PAYMENT_RECIEPT_ERROR = "DOWNLOAD_TRAINER_PAYMENT_RECIEPT_ERROR"