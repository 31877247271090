import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './config/AxiosConfig'
// import fetchClient from './config/AxiosConfig';
import Layout from './components/layout/index';
import FullLayout from './components/layout/fulllayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-calendar/dist/Calendar.css';
import 'react-tabs/style/react-tabs.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "react-calendar/dist/Calendar.css"
import './App.css';
import './assets/css/responsive.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { authenticate } from './store/Login/Actions';
import { AUTHENTICATE_FAILED } from './store/Login/Types';
import checkAuth from './Authentication/Auth'
import store from './store';

function App() {
    // fetchClient();
    const Loading = () => <div className="d-flex w-100 vh-100 align-items-center justify-content-center">Loading...</div>
    const token = JSON.parse(localStorage.getItem("webToken"));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    if (token && loggedInUser) {
        store.dispatch(authenticate(loggedInUser));
    } else {
        store.dispatch({ type: AUTHENTICATE_FAILED });
    }
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>

                <ToastContainer
                    position="bottom-right"
                    theme="dark"
                    autoClose={3000}
                />

                <Switch>
                    <Route exact path="/sport" component={(Layout)} />
                    <Route exact path="/about" component={(Layout)} />
                    <Route exact path="/trainerportal" component={checkAuth(Layout)} />
                    <Route exact path="/userportal" component={checkAuth(Layout)} />
                    <Route exact path="/request" component={checkAuth(Layout)} />
                    <Route exact path="/schedule_session" component={checkAuth(Layout)} />
                    {/* <Route exact path="/calendar" component={(Layout)} /> */}
                    <Route exact path="/privacy_Policy" component={(Layout)} />
                    <Route exact path="/terms_of_service" component={(Layout)} />

                    {/* full layout */}
                    <Route exact path="/service" component={(FullLayout)} />
                    <Route exact path="/register" component={(FullLayout)} />
                    <Route exact path="/trainer_register" component={(FullLayout)} />
                    <Route exact path="/user_login" component={(FullLayout)} />
                    <Route exact path="/trainer_login" component={(FullLayout)} />
                    <Route exact path="/user_reset/:restCode" component={(FullLayout)} />
                    <Route exact path="/trainer_reset/:restCode" component={(FullLayout)} />
                    <Route exact path="/goal" component={(FullLayout)} />
                    <Route exact path="/selectday" component={(FullLayout)} />
                    <Route exact path="/application" component={(FullLayout)} />
                    <Route exact path="/signup_select" component={(FullLayout)} />
                    <Route exact path="/login_select" component={(FullLayout)} />
                    <Route exact path="/forgotpassword" component={(FullLayout)} />
                    <Route exact path="/trainer_forgotpassword" component={(FullLayout)} />

                    <Route path="/" component={(Layout)} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
export default App;