import * as ACTION_TYPES from './Types'

const initialState = {
    customerInfo: {},
    trainerInfo: {},
    tempTrainerValue: {},
    loading: false,
    error: null,
    isLoggedIn: false,
    message: '',
    status: null
}

export const userSignupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CUSTOMER_SIGNUP_REQUEST:
        case ACTION_TYPES.TRAINNER_SIGNUP_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.TRAINNER_SIGNUP_FIRST_STEP_SUCCESS:
            return {
                ...state,
                tempTrainerValue: action.payload
            }
        case ACTION_TYPES.CUSTOMER_SIGNUP_SUCCESS:
            localStorage.setItem('webToken', JSON.stringify(action.payload.data.access_token));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                customerInfo: action.payload.data,
                message: action.payload.message,
                error: null,
                status: action.payload.status
            }
        case ACTION_TYPES.TRAINNER_SIGNUP_SUCCESS:
            localStorage.setItem('webToken', JSON.stringify(action.payload.data.access_token));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                trainerInfo: action.payload.data,
                message: action.payload.message,
                error: null,
                status: action.payload.status
            }
        case ACTION_TYPES.CUSTOMER_SIGNUP_FAIL:
        case ACTION_TYPES.TRAINNER_SIGNUP_FAIL:
            return {
                ...state,
                customerInfo: {},
                loading: false,
                isLoggedIn: false,
                // message: action.payload.message,
                // error: action.payload,
                // status: action.payload.status
            }
        default:
            return state
    }
}