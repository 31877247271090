export const ADD_USER_CARD_REQUEST = "ADD_USER_CARD_REQUEST"
export const ADD_USER_CARD_SUCCESS = "ADD_USER_CARD_SUCCESS"
export const ADD_USER_CARD_ERROR = "ADD_USER_CARD_ERROR"

export const GET_USER_CARD_REQUEST = "GET_USER_CARD_REQUEST"
export const GET_USER_CARD_SUCCESS = "GET_USER_CARD_SUCCESS"
export const GET_USER_CARD_ERROR = "GET_USER_CARD_ERROR"

export const DELETE_USER_CARD_REQUEST = "DELETE_USER_CARD_REQUEST"
export const DELETE_USER_CARD_SUCCESS = "DELETE_USER_CARD_SUCCESS"
export const DELETE_USER_CARD_ERROR = "DELETE_USER_CARD_ERROR"

export const UPDATE_USER_CARD_REQUEST = "UPDATE_USER_CARD_REQUEST"
export const UPDATE_USER_CARD_SUCCESS = "UPDATE_USER_CARD_SUCCESS"
export const UPDATE_USER_CARD_ERROR = "UPDATE_USER_CARD_ERROR"

export const ADD_TRAINER_CARD_REQUEST = "ADD_TRAINER_CARD_REQUEST"
export const ADD_TRAINER_CARD_SUCCESS = "ADD_TRAINER_CARD_SUCCESS"
export const ADD_TRAINER_CARD_ERROR = "ADD_TRAINER_CARD_ERROR"

export const GET_TRAINER_CARD_REQUEST = "GET_TRAINER_CARD_REQUEST"
export const GET_TRAINER_CARD_SUCCESS = "GET_TRAINER_CARD_SUCCESS"
export const GET_TRAINER_CARD_ERROR = "GET_TRAINER_CARD_ERROR"

export const DELETE_TRAINER_CARD_REQUEST = "DELETE_TRAINER_CARD_REQUEST"
export const DELETE_TRAINER_CARD_SUCCESS = "DELETE_TRAINER_CARD_SUCCESS"
export const DELETE_TRAINER_CARD_ERROR = "DELETE_TRAINER_CARD_ERROR"

export const UPDATE_TRAINER_CARD_REQUEST = "UPDATE_TRAINER_CARD_REQUEST"
export const UPDATE_TRAINER_CARD_SUCCESS = "UPDATE_TRAINER_CARD_SUCCESS"
export const UPDATE_TRAINER_CARD_ERROR = "UPDATE_TRAINER_CARD_ERROR"

export const ADD_TRAINER_ACCOUNT_REQUEST = "ADD_TRAINER_ACCOUNT_REQUEST"
export const ADD_TRAINER_ACCOUNT_SUCCESS = "ADD_TRAINER_ACCOUNT_SUCCESS"
export const ADD_TRAINER_ACCOUNT_ERROR = "ADD_TRAINER_ACCOUNT_ERROR"

export const UPLOAD_TRINER_DOCUMENT_REQUEST = "UPLOAD_TRINER_DOCUMENT_REQUEST"
export const UPLOAD_TRINER_DOCUMENT_SUCCESS = "UPLOAD_TRINER_DOCUMENT_SUCCESS"
export const UPLOAD_TRINER_DOCUMENT_ERROR = "UPLOAD_TRINER_DOCUMENT_ERROR"

export const WITHDRAW_TRAINER_AMOUNT_REQUEST = "WITHDRAW_TRAINER_AMOUNT_REQUEST"
export const WITHDRAW_TRAINER_AMOUNT_SUCCESS = "WITHDRAW_TRAINER_AMOUNT_SUCCESS"
export const WITHDRAW_TRAINER_AMOUNT_ERROR = "WITHDRAW_TRAINER_AMOUNT_ERROR"

export const GET_STRIPE_ACCOUNT_REQUEST = "GET_STRIPE_ACCOUNT_REQUEST"
export const GET_STRIPE_ACCOUNT_SUCCESS = "GET_STRIPE_ACCOUNT_SUCCESS"
export const GET_STRIPE_ACCOUNT_ERROR = "GET_STRIPE_ACCOUNT_ERROR"

export const GET_USER_ADDRESS_REQUEST = "GET_USER_ADDRESS_REQUEST"
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS"
export const GET_USER_ADDRESS_ERROR = "GET_USER_ADDRESS_ERROR"