import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes2 } from '../../routes';
// import '../../config/AxiosConfig'

const FullLayout = props => {
    const token = JSON.parse(localStorage.getItem("webToken"));

    if (!token) {
        <Redirect to='/' />
    }
    return (
        <>
            <main className="d-flex align-items-center justify-content-center flex-wrap min-vh-100 fmain-section">
                <Switch>
                    {routes2.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )}
                            />
                        ) : (null)
                    })}
                    <Redirect from='/' to="/home" />
                </Switch>
            </main>
        </>
    )
}

export default FullLayout;