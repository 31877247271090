export const GET_REQUEST_TRAINER_SUCCESS = "GET_REQUEST_TRAINER_SUCCESS"
export const GET_REQUEST_TRAINER_REQUEST = "GET_REQUEST_TRAINER_REQUEST"
export const GET_REQUEST_TRAINER_ERROR = "GET_REQUEST_TRAINER_ERROR"

export const ACCEPT_TRAINER_SUCCESS = "ACCEPT_TRAINER_SUCCESS"
export const ACCEPT_TRAINER_REQUEST = "ACCEPT_TRAINER_REQUEST"
export const ACCEPT_TRAINER_ERROR = "ACCEPT_TRAINER_ERROR"

export const GET_ALL_EVENTS_USER_SUCCESS = "GET_ALL_EVENTS_USER_SUCCESS"
export const GET_ALL_EVENTS_USER_REQUEST = "GET_ALL_EVENTS_USER_REQUEST"
export const GET_ALL_EVENTS_USER_ERROR = "GET_ALL_EVENTS_USER_ERROR"

export const GENERATE_USER_APPOINMENT_SUCCESS = "GENERATE_USER_APPOINMENT_SUCCESS"
export const GENERATE_USER_APPOINMENT_ERROR = "GENERATE_USER_APPOINMENT_ERROR"
export const GENERATE_USER_APPOINMENT_REQUEST = "GENERATE_USER_APPOINMENT_REQUEST"

export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS"
export const GET_PAYMENT_HISTORY_REQUEST = "GET_PAYMENT_HISTORY_REQUEST"
export const GET_PAYMENT_HISTORY_ERROR = "GET_PAYMENT_HISTORY_ERROR"

export const DOWNLOAD_PAYMENT_RECIEPT_SUCCESS = "DOWNLOAD_PAYMENT_RECIEPT_SUCCESS"
export const DOWNLOAD_PAYMENT_RECIEPT_REQUEST = "DOWNLOAD_PAYMENT_RECIEPT_REQUEST"
export const DOWNLOAD_PAYMENT_RECIEPT_ERROR = "DOWNLOAD_PAYMENT_RECIEPT_ERROR"

export const BACK_TO_SEARCH_REQUEST = "BACK_TO_SEARCH_REQUEST"
export const BACK_TO_SEARCH_SUCCESS = "BACK_TO_SEARCH_SUCCESS"
export const BACK_TO_SEARCH_ERROR = "BACK_TO_SEARCH_ERROR"

export const SCHEDULETIMES_SUCCESS = "SCHEDULETIMES_SUCCESS"