import * as ACTION_TYPES from './Types'

const initialState = {
    cmsData: {},
    error: null,
    loading: false,
    message: '',
}

export const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_TERMS_AND_CONDITION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.GET_TERMS_AND_CONDITION_SUCCESS:
            return {
                ...state,
                cmsData: action.payload.data,
                message: action.payload.message,
                loading: false,
                error: null,
            }
        case ACTION_TYPES.GET_TERMS_AND_CONDITION_ERROR:
            return {
                ...state,
                cmsData: {},
                loading: false,
            }
        default:
            return state
    }
}