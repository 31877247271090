import * as ACTION_TYPES from './Types'

const initialState = {
    loading: false,
    tabIndex: 0,
    trainerAppoinmets: [],
    transactions: [],
    message: '',
    error: null
}

export const trainerAppointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SELECT_TAB_INDEX":
            return {
                ...state,
                tabIndex: action.payload
            }
        case ACTION_TYPES.GET_TRAINER_APPOINMENT_REQUEST:
        case ACTION_TYPES.ACCEPT_APPOINTMENT_REQUEST:
        case ACTION_TYPES.BLOCK_DATE_REQUEST:
        case ACTION_TYPES.UNBLOCK_DATE_REQUEST:
        case ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_REQUEST:
        case ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_REQUEST:

            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.GET_TRAINER_APPOINMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                trainerAppoinmets: action.payload.data,
                error: null
            }
        case ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                transactions: action.payload?.data?.length > 0 ? action.payload?.data.reverse() : []
            }
        case ACTION_TYPES.ACCEPT_APPOINTMENT_SUCCESS:
        case ACTION_TYPES.BLOCK_DATE_SUCCESS:
        case ACTION_TYPES.UNBLOCK_DATE_SUCCESS:
        case ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_ERROR:
        case ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_SUCCESS:
        case ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_ERROR:
            return {
                ...state,
                loading: false,
            }
        case ACTION_TYPES.GET_TRAINER_APPOINMENT_ERROR:
            return {
                ...state,
                loading: false,
                trainerAppoinmets: []
            }
        case ACTION_TYPES.ACCEPT_APPOINTMENT_ERROR:
        case ACTION_TYPES.BLOCK_DATE_ERROR:
        case ACTION_TYPES.UNBLOCK_DATE_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}