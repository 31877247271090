// /**
//  * Axios config  setup
//  * Set interceptor for global api response error handling
//  * Set access token in headers
//  */
import axios from "axios";
import store from "../store"
import { API_BASE } from '../config/AppConstant'
import { LOGOUT_AXIOS } from "../store/Login/Types"

(function (axios) {
    let isRequestedToken = false
    axios.interceptors.request.use(
        config => {
            const modifiedConfig = config;
            const token = JSON.parse(localStorage.getItem("webToken"));
            if (token) {
                modifiedConfig.headers.Authorization = token;
            } else {
                modifiedConfig.headers.Authorization = null;
            }
            return modifiedConfig;
        },
        error => {
            //      setBrowserClose(false);
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use((response) => {
        return response
    }, function (error) {
        let originalRequest = error.config;

        // if (error.response.status === 401 &&) {
        //     router.push('/login');
        //     return Promise.reject(error);
        // }
        if (error.response.status === 401 || error.response.status === 402) {
            store.dispatch({
                type: LOGOUT_AXIOS
            });
        }
        if (error.response.status === 408 && !originalRequest._retry && !isRequestedToken) {
            isRequestedToken = true
            originalRequest._retry = true;
            const webToken = JSON.parse(localStorage.getItem("webToken"));
            const user = JSON.parse(localStorage.getItem("user"));
            let userType = 'user'
            if (user?.certificate_file) {
                userType = 'trainer'
            }
            return axios.post(`${API_BASE}${userType}/auth/refreshToken`,
                {
                    token: webToken
                })
                .then(res => {
                    axios.defaults.headers.common['Authorization'] = res.data.data.access_token
                    localStorage.setItem("webToken", JSON.stringify(res.data.data.access_token))
                    isRequestedToken = false
                    return axios(originalRequest);
                })
        }
        return Promise.reject(error);
    });


})(axios);

