import * as ACTION_TYPES from './Types'

const initialState = {
    userCard: [],
    userAddress: null,
    trainerAccount: null,
    loading: false,
    error: null,
    message: '',
    status: null
}

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ADD_USER_CARD_REQUEST:
        case ACTION_TYPES.ADD_TRAINER_CARD_REQUEST:
        case ACTION_TYPES.GET_USER_CARD_REQUEST:
        case ACTION_TYPES.GET_TRAINER_CARD_REQUEST:
        case ACTION_TYPES.DELETE_TRAINER_CARD_REQUEST:
        case ACTION_TYPES.UPDATE_TRAINER_CARD_REQUEST:
        case ACTION_TYPES.DELETE_USER_CARD_REQUEST:
        case ACTION_TYPES.UPDATE_USER_CARD_REQUEST:
        case ACTION_TYPES.ADD_TRAINER_ACCOUNT_REQUEST:
        case ACTION_TYPES.UPLOAD_TRINER_DOCUMENT_REQUEST:
        case ACTION_TYPES.WITHDRAW_TRAINER_AMOUNT_REQUEST:
        case ACTION_TYPES.GET_STRIPE_ACCOUNT_REQUEST:
        case ACTION_TYPES.GET_USER_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.ADD_USER_CARD_SUCCESS:
        case ACTION_TYPES.GET_USER_CARD_SUCCESS:
        case ACTION_TYPES.UPDATE_USER_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                userCard: action.payload.data,
                error: null,
            }
        case ACTION_TYPES.DELETE_USER_CARD_SUCCESS:
            return {
                ...state,
                userCard: [],
                loading: false,
                error: null,
            }
        case ACTION_TYPES.ADD_TRAINER_CARD_SUCCESS:
        case ACTION_TYPES.GET_TRAINER_CARD_SUCCESS:
        case ACTION_TYPES.UPDATE_TRAINER_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                trainerAccount: action.payload.data,
                error: null,
            }
        case ACTION_TYPES.DELETE_TRAINER_CARD_SUCCESS:
            return {
                ...state,
                trainerAccount: null,
                loading: false,
                error: null,
            }
        case ACTION_TYPES.ADD_TRAINER_ACCOUNT_SUCCESS:
        case ACTION_TYPES.ADD_TRAINER_ACCOUNT_ERROR:
        case ACTION_TYPES.UPLOAD_TRINER_DOCUMENT_SUCCESS:
        case ACTION_TYPES.UPLOAD_TRINER_DOCUMENT_ERROR:
        case ACTION_TYPES.WITHDRAW_TRAINER_AMOUNT_SUCCESS:
        case ACTION_TYPES.WITHDRAW_TRAINER_AMOUNT_ERROR:
        case ACTION_TYPES.GET_STRIPE_ACCOUNT_SUCCESS:
        case ACTION_TYPES.GET_STRIPE_ACCOUNT_ERROR:
            return {
                ...state,
                loading: false
            }
        case ACTION_TYPES.ADD_USER_CARD_ERROR:
        case ACTION_TYPES.ADD_TRAINER_CARD_ERROR:
        case ACTION_TYPES.GET_USER_CARD_ERROR:
        case ACTION_TYPES.GET_TRAINER_CARD_ERROR:
        case ACTION_TYPES.DELETE_TRAINER_CARD_ERROR:
        case ACTION_TYPES.DELETE_USER_CARD_ERROR:
            return {
                ...state,
                loading: false,
                userCard: [],
                trainerAccount: null,
            }
        case ACTION_TYPES.UPDATE_TRAINER_CARD_ERROR:
        case ACTION_TYPES.UPDATE_USER_CARD_ERROR:
            return {
                ...state,
                loading: false,
            }
        case ACTION_TYPES.GET_USER_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                userAddress: action.payload.data
            }
        default:
            return state
    }
}