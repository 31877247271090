import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

export default function checkAuth(ComposedComponent) {

    const Authentication = props => {
        const token = localStorage.getItem("webToken");
        // const { isLoggedIn } = useSelector(state => state.userLogin)
        useEffect(() => {
            if (!token) {
                props.history.push('/home')
            }

            // eslint-disable-next-line
        }, [token])

        return <ComposedComponent {...props} />
    }

    return withRouter((Authentication))
}
