import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import { routes } from '../../routes';
import { authenticate } from "../../store/TrainerLogin/Action";
import { AUTHENTICATE_FAILED } from "../../store/TrainerLogin/Types";
import store from "../../store";
// import '../../config/AxiosConfig'

const Layout = props => {
    const token = JSON.parse(localStorage.getItem("webToken"));
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    if (token && loggedInUser) {
        store.dispatch(authenticate(loggedInUser));
    } else {
        store.dispatch({ type: AUTHENTICATE_FAILED });
    }
    if (!token) {
        <Redirect to='/' />
    }
    return (
        <>
            <Header />
            <main className="main-section">
                {/* <ToastContainer /> */}
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )}
                            />
                        ) : (null)
                    })}
                    <Redirect from='/' to="/home" />
                </Switch>
            </main>
            <Footer />
        </>
    )
}

export default Layout;