import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'

// To login
export const trainerLogin = user => dispatch => {
    dispatch({ type: ACTION_TYPES.TRAINER_LOGIN_REQUEST })
    return axios
        .post(`${API_BASE}trainer/auth/login`, { ...user })
        .then((res) => {
            if (res.status === 200) {
                // set access token for rest of the api
                axios.defaults.headers.common['Authorization'] = res.data.data.access_token;
                dispatch({ type: ACTION_TYPES.TRAINER_LOGIN_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.TRAINER_LOGIN_FAIL, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.TRAINER_LOGIN_FAIL, payload: err.response })
            throw err
        })
}

export const forgotPassword = (data) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.TRAINER_FORGOT_PASSWORD_REQUEST })
    return axios
        .post(`${API_BASE}trainer/auth/forgotPassword`, data)
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({
                    type: ACTION_TYPES.TRAINER_FORGOT_PASSWORD_SUCCESS,
                    payload: res.data,
                });
                return res.data;
            }
            if (res.data.status === 400) {
                dispatch({
                    type: ACTION_TYPES.TRAINER_FORGOT_PASSWORD_ERROR,
                    payload: res.data,
                });
                return res.data;
            }

        })
        .catch((err) => {
            dispatch({
                type: ACTION_TYPES.TRAINER_FORGOT_PASSWORD_ERROR,
                payload: err.response,
            });
            throw err;
        });
};

//To Set New Password
export const resetPassword = data => dispatch => {
    dispatch({ type: ACTION_TYPES.TRAINER_RESET_PASSWORD_REQUEST })
    return axios
        .post(`${API_BASE}trainer/auth/resetPassword`, data)
        .then(res => {
            dispatch({ type: ACTION_TYPES.TRAINER_RESET_PASSWORD_SUCCESS, payload: res.data })
            return res.data
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.TRAINER_RESET_PASSWORD_ERROR, payload: err.response })
            throw err
        })
}

// user logout
export const trainerLogout = () => dispatch => {
    dispatch({ type: ACTION_TYPES.TRAINER_LOGOUT_REQUEST })
    return axios
        .get(`${API_BASE}trainer/api/logout`)
        .then(res => {
            dispatch({ type: ACTION_TYPES.TRAINER_LOGOUT, payload: res.data })
            return res.data
        })
        .catch(err => {
            dispatch({ type: ACTION_TYPES.TRAINER_LOGOUT_ERROR, payload: err.response })
            throw err
        })

}

//To reAuthenticate if the cookie is not expired
export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

// Change Password
export const changePassword = (data) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.TRAINER_CHANGE_PASSWORD_REQUEST })
    return axios
        .post(`${API_BASE}trainer/api/changePassword`, data)
        .then((res) => {
            if (res.data.status === 200) {
                dispatch({
                    type: ACTION_TYPES.TRAINER_CHANGE_PASSWORD_SUCCESS,
                    payload: res.data,
                });
                return res.data;
            }
            if (res.data.status === 400) {
                dispatch({
                    type: ACTION_TYPES.TRAINER_CHANGE_PASSWORD_ERROR,
                    payload: res.data,
                });
                return res.data;
            }

        })
        .catch((err) => {
            dispatch({
                type: ACTION_TYPES.TRAINER_CHANGE_PASSWORD_ERROR,
                payload: err.response.data,
            });
            throw err;
        });
};