import React from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/footer.css"
import { toAbsoluteUrl } from '../../utils'
import "../svg/footerlogo"
// import Footerlogo from '../svg/footerlogo'
const Footer = () => {
    return (
        <>
            <footer className="d-flex flex-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="footer-logo">
                                <Link to='/'>
                                    {/* <Footerlogo /> */}
                                    <img src={toAbsoluteUrl("/images/new_logo.png")} alt="logo-footer-img" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="left-footer">
                                <ul className="footer-menu">
                                    <li><Link to="/privacy_Policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms_of_service">Terms of Service</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;