import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import "../../assets/css/header.css"
import { userLogout } from '../../store/Login/Actions';
import { setTabIndex } from '../../store/TrainerAppointment/Action';
import { trainerLogout } from '../../store/TrainerLogin/Action';
import { toAbsoluteUrl } from '../../utils';
import "../svg/logo"
// import Logo from '../svg/logo'

const Header = () => {
    const token = localStorage.getItem('webToken')
    const [small, setSmall] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setSmall(window.pageYOffset > 0)
            );
        }
    }, []);

    const [isActive, setActive] = useState("false");
    const handleToggle = () => { setActive(!isActive); };

    const handleSignout = (e) => {
        e.preventDefault()
        if (!user?.certificate_file) {
            dispatch(userLogout())
                .then(res => {
                    toast.success(res.message)
                    history.push('/home')
                }).catch(err => {
                    toast.error(err?.response?.data?.message || 'Please try again later.')
                })
        } else {
            dispatch(trainerLogout())
                .then(res => {
                    toast.success(res.message)
                    dispatch(setTabIndex(0))
                    history.push('/home')
                }).catch(err => {
                    toast.error(err?.response?.data?.message || 'Please try again later.')
                })
        }
    }

    return (
        <>
            <header className={`header d-flex flex-wrap ${small ? "scroll-header" : ""}`} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-4">
                            <div className="left-logo">
                                <Link to='/'>
                                    <img src={toAbsoluteUrl("/images/new_logo_full.png")} alt="logo-header-img" />
                                    {/* <Logo /> */}
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-9 col-8 p-0">
                            <nav className="d-flex flex-wrap align-items-center justify-content-end">
                                <ul className={`navbar ${isActive ? "" : "open-menu"}`}>
                                    {!token &&
                                        <>
                                            <li> <NavLink to="/service" activeClassName="active" onClick={handleToggle} >Find Your Match</NavLink> </li>
                                            <li> <NavLink to="/sport" activeClassName="active" onClick={handleToggle} >Activities</NavLink> </li>
                                        </>
                                    }
                                    <li> <NavLink to="/about" activeClassName="active" onClick={handleToggle}>About Us</NavLink> </li>
                                    {token && user?.certificate_file && <li> <NavLink to='/trainerportal' activeClassName="active" onClick={handleToggle} >Trainer Portal</NavLink> </li>}
                                    {token && !user?.certificate_file && <>
                                        <li> <NavLink to='/request' activeClassName="active" onClick={handleToggle} >Find Trainer</NavLink> </li>
                                        <li> <NavLink to='/userportal' activeClassName="active" onClick={handleToggle} >User Portal</NavLink> </li>
                                    </>}
                                </ul>
                                <div className={`toggle-area ${isActive ? "" : "close-btn1"}`} onClick={handleToggle}>
                                    <ul className="toggle">
                                        <li className="top"></li>
                                        <li className="middel"></li>
                                        <li className="bottom"></li>
                                    </ul>
                                </div>
                                <div className="right-btn d-flex flex-wrap ">
                                    <ul className="btn-box">
                                        {!token ? <>
                                            <li> <Link to="/signup_select">Sign Up</Link> </li>
                                            <li> <Link to="/login_select">Login</Link> </li>
                                        </> : <>
                                            {token &&
                                                <li className='name-btn' onClick={handleSignout}> <Link to="#">Sign Out</Link> </li>
                                            }
                                        </>}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <div style={{ color: "white", position: 'absolute', top: '0px', right: '10px', height: '25px' }}>Call Us Today - (999) 999-9999</div> */}
            </header>
        </>
    )
}
export default Header;