import * as ACTION_TYPES from './Types'

const initialState = {
    userInfo: {},
    loading: false,
    error: null,
    isLoggedIn: false,
    message: '',
    status: null
}

export const userLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.USER_LOGIN_REQUEST:
        case ACTION_TYPES.FORGOT_PASSWORD_REQUEST:
        case ACTION_TYPES.CHANGE_PASSWORD_REQUEST:
        case ACTION_TYPES.RESET_PASSWORD_REQUEST:
        case ACTION_TYPES.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.USER_LOGIN_SUCCESS:
            localStorage.setItem('webToken', JSON.stringify(action.payload.data.access_token));
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return {
                ...state,
                loading: false,
                isLoggedIn: true,
                userInfo: action.payload.data,
                message: action.payload.message,
                error: null,
                status: action.payload.status
            }

        case ACTION_TYPES.AUTHENTICATE_USER:
            return {
                ...state,
                isLoggedIn: true,
                loading: false,
                user: action.payload,
                error: null
            }

        case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
        case ACTION_TYPES.FORGOT_PASSWORD_ERROR:
        case ACTION_TYPES.RESET_PASSWORD_ERROR:
        case ACTION_TYPES.RESET_PASSWORD_SUCCESS:

            return {
                ...state,
                loading: false,
                message: action.payload.message,
                status: action.payload.status
            }

        case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
        case ACTION_TYPES.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                status: action.payload.status,
                message: action.payload.message
            }

        case ACTION_TYPES.USER_LOGIN_FAIL:
        case ACTION_TYPES.LOGOUT:
            localStorage.removeItem("webToken");
            localStorage.removeItem("user");
            return {
                ...state,
                userInfo: {},
                loading: false,
                isLoggedIn: false,
                // message: action.payload.message,
                // error: action.payload,
                // status: action.payload.status
            }
        case ACTION_TYPES.LOGOUT_AXIOS:
            localStorage.removeItem("webToken");
            localStorage.removeItem("user");
            return {
                ...state,
                userInfo: {},
                loading: false,
                isLoggedIn: false,
            }
        default:
            return state
    }
}