import * as ACTION_TYPES from './Types'

const initialState = {
    trainerProfile: {},
    loading: false,
    error: null,
    message: '',
    status: null
}

export const trainerProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_TRAINER_DETAILS_REQUEST:
        case ACTION_TYPES.UPDATE_TRAINER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTION_TYPES.GET_TRAINER_DETAILS_SUCCESS:
        case ACTION_TYPES.UPDATE_TRAINER_DETAILS_SUCCESS:
            localStorage.setItem('user', JSON.stringify(action.payload.data));
            return {
                ...state,
                loading: false,
                trainerProfile: action.payload.data,
                message: action.payload.message,
                error: null,
                status: action.payload.status
            }
        case ACTION_TYPES.GET_TRAINER_DETAILS_FAIL:
        case ACTION_TYPES.UPDATE_TRAINER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                trainerProfile: {},
                // message: action.payload.message,
                // error: action.payload,
                // status: action.payload.status
            }
        default:
            return state
    }
}