// login
export const TRAINER_LOGIN_REQUEST = "TRAINER_LOGIN_REQUEST"
export const TRAINER_LOGIN_SUCCESS = "TRAINER_LOGIN_SUCCESS"
export const TRAINER_LOGIN_FAIL = "TRAINER_LOGIN_FAIL"

// for re authenticate
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

// FORGOT PASSWORD ACTIONS
export const TRAINER_FORGOT_PASSWORD_SUCCESS = "TRAINER_FORGOT_PASSWORD_SUCCESS"
export const TRAINER_FORGOT_PASSWORD_REQUEST = "TRAINER_FORGOT_PASSWORD_REQUEST"
export const TRAINER_FORGOT_PASSWORD_ERROR = "TRAINER_FORGOT_PASSWORD_ERROR"

//changepassword
export const TRAINER_CHANGE_PASSWORD_SUCCESS = "TRAINER_CHANGE_PASSWORD_SUCCESS"
export const TRAINER_CHANGE_PASSWORD_REQUEST = "TRAINER_CHANGE_PASSWORD_REQUEST"
export const TRAINER_CHANGE_PASSWORD_ERROR = "TRAINER_CHANGE_PASSWORD_ERROR"

export const TRAINER_LOGOUT = "TRAINER_LOGOUT"
export const TRAINER_LOGOUT_REQUEST = "TRAINER_LOGOUT_REQUEST"
export const TRAINER_LOGOUT_ERROR = "TRAINER_LOGOUT_ERROR"

// reset password
export const TRAINER_RESET_PASSWORD_SUCCESS = "TRAINER_RESET_PASSWORD_SUCCESS"
export const TRAINER_RESET_PASSWORD_REQUEST = "TRAINER_RESET_PASSWORD_REQUEST"
export const TRAINER_RESET_PASSWORD_ERROR = "TRAINER_RESET_PASSWORD_ERROR"
