import * as ACTIONTYPES from './Types'

const initialState = {
    goal: "meet_fitness",
    plan: [],
    service: "",
    loading: false,
    services: [],
    message: '',
    error: null
}

export const planGolaServiceReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTIONTYPES.SELECT_GOAL:
            return {
                ...state,
                goal: action.payload
            }
        case ACTIONTYPES.SELECT_PLAN:
            return {
                ...state,
                plan: action.payload
            }
        case ACTIONTYPES.SELECT_SERVICE:
            return {
                ...state,
                service: action.payload
            }
        case ACTIONTYPES.GET_SERVICE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ACTIONTYPES.GET_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                services: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTIONTYPES.GET_SERVICE_FAIL:
            return {
                ...state,
                services: [],
                loading: false,
                service: "",
                // message: action.payload.message,
                // error: action.payload,
            }
        default:
            return state;
    }

}