import axios from 'axios'
import { toast } from 'react-toastify'
import { API_BASE } from '../../config/AppConstant'
import * as ACTION_TYPES from './Types'

export const getAppoinmnets = () => dispatch => {
    dispatch({ type: ACTION_TYPES.GET_TRAINER_APPOINMENT_REQUEST })

    return axios
        .post(`${API_BASE}trainer/api/getAppointments`)
        .then((res) => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.GET_TRAINER_APPOINMENT_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.GET_TRAINER_APPOINMENT_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_TRAINER_APPOINMENT_ERROR, payload: err.response })
            throw err
        })
}

export const appointmentAccept = (data) => dispatch => {
    dispatch({ type: ACTION_TYPES.ACCEPT_APPOINTMENT_REQUEST })

    return axios
        .post(`${API_BASE}trainer/api/setAppointmentStatus`, data)
        .then((res) => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.ACCEPT_APPOINTMENT_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.ACCEPT_APPOINTMENT_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.ACCEPT_APPOINTMENT_ERROR, payload: err.response })
            throw err
        })
}

export const blockedDate = (data) => dispatch => {
    dispatch({ type: ACTION_TYPES.BLOCK_DATE_REQUEST })

    return axios
        .post(`${API_BASE}trainer/api/blockSchedule`, data)
        .then((res) => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.BLOCK_DATE_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.BLOCK_DATE_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.BLOCK_DATE_ERROR, payload: err.response })
            throw err
        })
}
export const unBlockDate = (data) => dispatch => {
    dispatch({ type: ACTION_TYPES.UNBLOCK_DATE_REQUEST })
    return axios
        .post(`${API_BASE}trainer/api/unblockSchedule`, data)
        .then((res) => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.UNBLOCK_DATE_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.UNBLOCK_DATE_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.UNBLOCK_DATE_ERROR, payload: err.response })
            throw err
        })
}

export const getTransactionHistory = () => dispatch => {
    dispatch({ type: ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_REQUEST })

    return axios
        .get(`${API_BASE}trainer/api/transactionHistory`)
        .then((res) => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.GET_TRAINER_PAYMENT_HISTORY_ERROR, payload: err.response })
            throw err
        })
}

export const downloadTransactionPdf = (data) => dispatch => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    dispatch({ type: ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_REQUEST })
    return fetch(`${API_BASE}trainer/api/downloadTransaction/${data.id}/${tz}`,
        {
            method: 'GET',
            headers: new Headers({
                'Authorization': JSON.parse(localStorage.getItem("webToken")),
            }),
        })
        .then(res => {
            if (res.status === 200) {
                dispatch({ type: ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_SUCCESS })
                return res.blob();
            } else {
                throw new Error("Unable to download pdf.")
            }
        })
        .then(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `payment-${data.id.substr(6)}.pdf`;
            link.click();
        }).catch(err => {
            dispatch({ type: ACTION_TYPES.DOWNLOAD_TRAINER_PAYMENT_RECIEPT_ERROR })
            toast.error(err)
        })

}

export const setTabIndex = (index) => {
    return {
        type: "SELECT_TAB_INDEX",
        payload: index,
    }
}