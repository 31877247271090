import React from 'react';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/about'));
const Match = React.lazy(() => import('./pages/match'));
const Sport = React.lazy(() => import('./pages/sport'));
const Trainerportal = React.lazy(() => import('./pages/trainerportal'));
const Userportal = React.lazy(() => import('./pages/userportal'));
const Request = React.lazy(() => import('./pages/request'));
const Session = React.lazy(() => import('./pages/schedulesession'));
// const SessionCalendar = React.lazy(() => import('./pages/sessioncalendar'));
const Privacy = React.lazy(() => import('./pages/privacy'));
const Terms = React.lazy(() => import('./pages/terms'));

export const routes = [
    { path: '/home', exact: true, name: 'Home', component: Home },
    { path: '/about', exact: true, name: 'about', component: About },
    { path: '/findyourmatch', exact: true, name: 'findyourmatch', component: Match },
    { path: '/sport', exact: true, name: 'sport', component: Sport },
    { path: '/trainerportal', exact: true, name: 'Trainerportal', component: Trainerportal },
    { path: '/userportal', exact: true, name: 'Userportal', component: Userportal },
    { path: '/request', exact: true, name: 'Request', component: Request },
    { path: '/schedule_session', exact: true, name: 'Session', component: Session },
    // { path: '/calendar', exact: true, name: 'SessionCalendar', component: SessionCalendar },
    { path: '/privacy_Policy', exact: true, name: 'Privacy', component: Privacy },
    { path: '/terms_of_service', exact: true, name: 'Terms', component: Terms },
]

// route 2
const Service = React.lazy(() => import('./pages/service'));
const Registere = React.lazy(() => import('./pages/steps/registere'));
const TrainerRegistere = React.lazy(() => import('./pages/steps/trainerRegister'));
const Login = React.lazy(() => import('./pages/steps/login'));
const TrainerLogin = React.lazy(() => import('./pages/steps/TrainerLogin'));
const Changepwd = React.lazy(() => import('./pages/steps/changepassword'));
const Goal = React.lazy(() => import('./pages/steps/goal'));
const Selectday = React.lazy(() => import('./pages/steps/selectday'));
const Application = React.lazy(() => import('./pages/steps/application'));
const Select = React.lazy(() => import('./pages/steps/select'));
const LoginSelect = React.lazy(() => import('./pages/steps/LoginSelect'));
const Forgotpassword = React.lazy(() => import('./pages/steps/forgotpassword'));
const TrainerForgotPass = React.lazy(() => import('./pages/steps/trainerForgotPass'));
const TrainerChangePass = React.lazy(() => import('./pages/steps/trainerChangePass'));


export const routes2 = [
    { path: '/service', exact: true, name: 'Service', component: Service },
    { path: '/register', exact: true, name: 'Registere', component: Registere },
    { path: '/trainer_register', exact: true, name: 'Registere', component: TrainerRegistere },
    { path: '/user_login', exact: true, name: 'Login', component: Login },
    { path: '/trainer_login', exact: true, name: 'TrainerLogin', component: TrainerLogin },
    { path: '/user_reset/:restCode', exact: true, name: 'Changepwd', component: Changepwd },
    { path: '/goal', exact: true, name: 'Goal', component: Goal },
    { path: '/selectday', exact: true, name: 'Selectday', component: Selectday },
    { path: '/application', exact: true, name: 'Application', component: Application },
    { path: '/signup_select', exact: true, name: 'Select', component: Select },
    { path: '/login_select', exact: true, name: 'LoginSelect', component: LoginSelect },
    { path: '/forgotpassword', exact: true, name: 'Forgotpassword', component: Forgotpassword },
    { path: '/trainer_forgotpassword', exact: true, name: 'Forgotpassword', component: TrainerForgotPass },
    { path: '/trainer_reset/:restCode', exact: true, name: 'Forgotpassword', component: TrainerChangePass },
]
// trainer_reset/:restCode
// user_reset/:restCode