export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_IMAGE_BASE: API_IMAGE_BASE } = process.env;

//To concate the path for the user profile image path
export const toUserRealProfileImage = pathname => (`${process.env.REACT_APP_IMAGE_BASE}${pathname}`)

// //To concate the path for the category image path
// export const toCategoryImage = pathname => (`${process.env.REACT_APP_IMAGE_BASE}category/${pathname}`)

// //To concate the path for the group image path
// export const toGroupImage = pathname => (`${process.env.REACT_APP_IMAGE_BASE}group/${pathname}`)

// //To concate the path for the expense image path
// export const toExpenseImage = pathname => (`${process.env.REACT_APP_IMAGE_BASE}bills/${pathname}`)