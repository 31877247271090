// login
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

// for re authenticate
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

// FORGOT PASSWORD ACTIONS
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

//changepassword
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

export const LOGOUT = "LOGOUT"
export const LOGOUT_AXIOS = "LOGOUT_AXIOS"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_ERROR = "LOGOUT_ERROR"

// reset password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
